<template>
    <base-card>
        <v-card-title
            v-if="title"
            class="title-post"
        >
            <span> {{ title }}</span>
        </v-card-title>


        <v-card-text>

            <gameComponnent
                v-if="gameId"
                :game-id="gameId"
            />


        </v-card-text>
    </base-card>

</template>

<script>

import postMixin from '@/mixins/postMixin'
import gameComponnent from "@/components/games/gameComponnent";

export default {
    name: 'GameView',
    components: { gameComponnent},
    mixins: [postMixin],
    data(){
        return {
            gameId: null
        }
    },
    computed: {
        title() {

            return null;
        }
    },
    created() {
        this.gameId = +this.$route.params.id;
    },
    methods: {}

}
</script>

